<template>
  <div
    class="flex h-12 w-[7rem] items-center rounded-full border border-theme-primary/50"
  >
    <button
      type="button"
      class="flex h-full flex-1 items-center justify-center"
      @click="$emit('update:modelValue', Math.max(modelValue - 1, min))"
    >
      <svg
        class="h-3 w-3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <line
          x1="40"
          y1="128"
          x2="216"
          y2="128"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        />
      </svg>
    </button>
    <div class="min-w-[25px] px-1 text-center">
      {{ modelValue }}
    </div>
    <button
      type="button"
      class="flex h-full flex-1 items-center justify-center"
      @click="$emit('update:modelValue', Math.min(modelValue + 1, max))"
    >
      <svg
        class="h-3 w-3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <line
          x1="40"
          y1="128"
          x2="216"
          y2="128"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        />
        <line
          x1="128"
          y1="40"
          x2="128"
          y2="216"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 99,
    },
  },
  emits: ['update:modelValue'],
};
</script>
