<template>
  <RouterLink
    :to="{ name: 'order', params: { orderUuid: recentOrder.uuid } }"
    class="flex items-center rounded-xl bg-theme-background px-5 py-3 text-theme-text shadow-[rgba(var(--color-theme-text),0.2)_5px_5px_20px_0px]"
  >
    <div
      class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full ltr:mr-3 rtl:ml-3"
      :class="{
        'bg-theme-primary text-theme-button-text':
          type === 'success' || type === 'error',
        'bg-theme-primary/10 text-theme-primary': type === 'neutral',
      }"
    >
      <CheckBold v-if="type === 'success'" class="block h-6 w-6" />
      <XBold v-if="type === 'error'" class="block h-6 w-6" />
      <ClockBold
        v-if="type === 'neutral'"
        class="block h-6 w-6 animate-pulse"
      />
    </div>
    <div class="flex flex-1 flex-col justify-center">
      <div class="font-semibold">Order #{{ recentOrder.orderNumber }}</div>
      <div class="text-sm">
        {{ $formatDateTime(recentOrder.createdAt, 'MMM d, hh:mm a') }}
      </div>
    </div>
    <div class="flex shrink-0 items-center">
      <CaretRightBold class="h-5 w-5" />
    </div>
  </RouterLink>
</template>

<script>
import CaretRightBold from '@/phosphoricons/CaretRightBold.vue';
import XBold from '@/phosphoricons/XBold.vue';
import CheckBold from '@/phosphoricons/CheckBold.vue';
import ClockBold from '@/phosphoricons/ClockBold.vue';

export default {
  components: { ClockBold, CheckBold, XBold, CaretRightBold },
  props: {
    recentOrder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      if (this.recentOrder.status === 'COMPLETED') {
        return 'success';
      }

      if (this.recentOrder.status === 'REJECTED') {
        return 'error';
      }

      return 'neutral';
    },
  },
};
</script>
