<template>
  <div>
    <button
      v-for="n in 5"
      :key="n"
      type="button"
      class="h-6 w-6 transition focus:outline-none ltr:mr-2 rtl:ml-2"
      :class="[
        modelValue >= n
          ? 'text-theme-primary'
          : 'text-theme-primary/50 hover:text-theme-primary/70',
      ]"
      @click="$emit('update:modelValue', n)"
    >
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-full w-full"
      >
        <path
          d="M12 0L15.668 7.62569L24 8.78546L17.936 14.6579L19.416 23L12 19.0028L4.583 23L6.064 14.6579L0 8.78546L8.332 7.62569L12 0Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
};
</script>
