<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.9967 0.0109032C52.6557 -0.100305 54.751 0.826431 56.8709 2.18564C59.5544 3.90319 62.581 7.30123 66.3184 9.45125C71.5752 12.4786 81.3045 8.3021 86.2794 15.7654C89.1835 20.1149 89.3183 23.5253 89.5388 26.8986C89.7717 30.5314 90.4088 33.88 94.0972 38.7979C100.212 46.9532 101.486 52.3777 98.3369 58.0246C96.1925 61.8798 91.671 64.0175 90.6294 66.4641C88.3993 71.6538 90.8622 75.5708 87.8234 81.6255C85.7035 85.8267 82.444 88.5945 78.094 90.0032C74.4302 91.1894 70.7542 89.4718 67.8133 90.7198C62.6546 92.9069 58.856 97.9854 54.751 99.2705C53.1703 99.7648 51.5896 100.012 50.0089 99.9996C48.4282 100.012 46.8475 99.7648 45.2668 99.2705C41.1618 97.9854 37.3632 92.9069 32.2045 90.7198C29.2636 89.4718 25.5876 91.2017 21.9238 90.0032C17.5738 88.5945 14.3143 85.8267 12.1945 81.6255C9.14332 75.5708 11.6063 71.6538 9.38839 66.4641C8.34684 64.0175 3.82528 61.8798 1.68091 58.0246C-1.49276 52.3777 -0.218391 46.9532 5.89613 38.8103C9.58445 33.8924 10.2216 30.5438 10.4544 26.911C10.675 23.5376 10.8098 20.1273 13.7139 15.7778C18.7011 8.31446 28.4304 12.4909 33.6749 9.46361C37.4123 7.31358 40.4389 3.91555 43.1224 2.198C45.23 0.826431 47.3376 -0.112662 49.9967 0.0109032Z"
      fill="#FBCB18"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.9999 24C50.7482 24 51.4316 24.445 51.7627 25.1478L58.7321 39.9393L74.3168 42.3113C75.0573 42.424 75.6725 42.9672 75.9037 43.7126C76.135 44.458 75.9421 45.2762 75.4063 45.8233L64.129 57.3372L66.7915 73.5928C66.918 74.3653 66.6148 75.146 66.0095 75.6067C65.4042 76.0674 64.6017 76.1282 63.9393 75.7636L49.9999 68.0893L36.0607 75.7636C35.3983 76.1282 34.5958 76.0674 33.9905 75.6067C33.3851 75.146 33.082 74.3653 33.2085 73.5928L35.8711 57.3372L24.5937 45.8233C24.0579 45.2762 23.865 44.458 24.0963 43.7126C24.3275 42.9672 24.9427 42.424 25.6832 42.3113L41.268 39.9393L48.2371 25.1478C48.5682 24.445 49.2516 24 49.9999 24Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>
