<template>
  <div class="relative h-10 w-10">
    <ADeepCircleButton>
      <template #icon><GlobeBold /></template>
    </ADeepCircleButton>

    <select
      class="absolute inset-0 m-0 cursor-pointer appearance-none p-0 opacity-0"
      @change="setLanguage($event.target.value)"
    >
      <option
        v-for="availableLanguage in $store.getters.availableLanguages"
        :key="availableLanguage"
        :value="availableLanguage"
        :selected="availableLanguage === $route.params.languageCode"
      >
        {{ getLanguageName(availableLanguage) }}
      </option>
    </select>
  </div>
</template>

<script>
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import { getLanguageByCode } from '@/utils/language.js';
import GlobeBold from '@/phosphoricons/GlobeBold.vue';
export default {
  components: { GlobeBold, ADeepCircleButton },
  methods: {
    getLanguageName(languageCode) {
      const language = getLanguageByCode(languageCode);
      return language ? language.nativeName : languageCode;
    },
    setLanguage(languageCode) {
      this.$router.push({
        params: {
          languageCode: languageCode,
        },
        query: this.$route.query,
      });
    },
  },
};
</script>
