<template>
  <div v-if="recentOrders.length > 0">
    <div class="space-y-4">
      <RecentOrder
        v-for="recentOrder in recentOrders"
        :key="recentOrder.uuid"
        :recent-order="recentOrder"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RecentOrder from '@menu/Shared/RecentOrder.vue';

export default {
  components: { RecentOrder },
  data() {
    return {
      recentOrders: [],
    };
  },
  created() {
    this.loadRecentOrders();
  },
  methods: {
    loadRecentOrders() {
      if (!this.$store.state.isOrderMode) {
        return;
      }

      axios
        .get('/api/orders', {
          params: {
            restaurantId: this.$store.state.restaurant.id,
            visitorUuid: this.$store.state.visitorUuid,
          },
        })
        .then((res) => {
          this.recentOrders = res.data.data;
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>
