// Inspired by: https://github.com/ndelvalle/v-click-outside/blob/1f184835f9c17d2bc6334b9dd2fb300b51183bea/src/v-click-outside.js

const HANDLERS_PROPERTY = '__CLICK_OUTSIDE_HANDLERS__';

function initialize(el, handler) {
  if (typeof handler !== 'function') {
    throw new Error('v-click-outside: Handler must be a function');
  }

  let lastMousedownInside = false;
  let lastMouseupInside = false;

  const supportsTouch = 'ontouchstart' in window;

  el[HANDLERS_PROPERTY] = [
    {
      event: supportsTouch ? 'touchstart' : 'mousedown',
      handler: (e) => {
        lastMousedownInside = el.contains(e.target);
      },
    },
    {
      event: supportsTouch ? 'touchend' : 'mouseup',
      handler: (e) => {
        lastMouseupInside = el.contains(e.target);

        if (!lastMousedownInside && !lastMouseupInside) {
          handler(e);
        }
      },
    },
  ];

  setTimeout(() => {
    if (el[HANDLERS_PROPERTY]) {
      el[HANDLERS_PROPERTY].forEach(({ event, handler }) => {
        document.addEventListener(event, handler);
      });
    }
  }, 0);
}

function terminate(el) {
  (el[HANDLERS_PROPERTY] ?? []).forEach(({ event, handler }) => {
    document.removeEventListener(event, handler);
  });
  delete el[HANDLERS_PROPERTY];
}

export default {
  beforeMount(el, { value }) {
    initialize(el, value);
  },
  updated(el, { value }) {
    terminate(el);
    initialize(el, value);
  },
  unmounted(el) {
    terminate(el);
  },
};
