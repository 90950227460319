<template>
  <MainLayout title="About">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <ADeepCircleButton
          class="flex-shrink-0"
          @click="$router.push({ name: 'menu' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
        <h1 class="flex-1 truncate px-4 text-center text-lg font-medium">
          {{ restaurant.name }}
        </h1>
        <div class="h-10 w-10 flex-shrink-0"></div>
      </div>
    </div>

    <div class="p-4">
      <div>
        <div v-if="restaurant.description" class="mb-5">
          {{ restaurant.description }}
        </div>
        <div v-else class="mb-5">Thanks for visiting us!</div>
        <AboutRestaurant
          show-opening-hours
          show-contact-number
          show-address
          show-email
          show-website
          show-instagram
          show-tiktok
          show-facebook
          show-whatsapp
          show-google-reviews-url
          show-tripadvisor-url
        />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@menu/Shared/MainLayout.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import { mapGetters, mapState } from 'vuex';
import AboutRestaurant from '@menu/Shared/AboutRestaurant.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';

export default {
  components: {
    ArrowLeftBold,
    AboutRestaurant,
    ADeepCircleButton,
    MainLayout,
  },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu']),
  },
  methods: {
    beautifyUrl(url) {
      const urlObject = new URL(url);

      return `${urlObject.hostname}${
        urlObject.pathname !== '/' ? urlObject.pathname : ''
      }`;
    },
  },
};
</script>
