<template>
  <div class="mt-5">
    <div ref="paymentElement"></div>
    <button
      class="menu-button-primary mt-6 w-full"
      type="button"
      :disabled="submitting"
      @click="submit"
    >
      {{ submitting ? 'Processing...' : 'Complete Payment' }}
    </button>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js/pure';

export default {
  props: {
    publishableKey: {
      type: String,
      required: true,
    },
    clientSecret: {
      type: String,
      required: true,
    },
    stripeAccount: {
      type: String,
      required: true,
    },
    returnUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
    };
  },
  async mounted() {
    this.stripe = await loadStripe(this.publishableKey, {
      stripeAccount: this.stripeAccount,
    });

    const appearance = {
      /* appearance */
    };
    const options = {
      paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
    };

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecret,
      appearance,
    });
    const paymentElement = this.elements.create('payment', options);
    paymentElement.mount(this.$refs.paymentElement);
  },
  methods: {
    async submit() {
      this.submitting = true;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: this.returnUrl,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.$toast.error(error.message, {
          timeout: 3000,
        });
      } else {
        this.$toast.error('An unexpected error occurred.', {
          timeout: 3000,
        });
      }

      this.submitting = false;
    },
  },
};
</script>
