<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.9967 0.0109032C52.6557 -0.100305 54.751 0.826431 56.8709 2.18564C59.5544 3.90319 62.581 7.30123 66.3184 9.45125C71.5752 12.4786 81.3045 8.3021 86.2794 15.7654C89.1835 20.1149 89.3183 23.5253 89.5388 26.8986C89.7717 30.5314 90.4088 33.88 94.0972 38.7979C100.212 46.9532 101.486 52.3777 98.3369 58.0246C96.1925 61.8798 91.671 64.0175 90.6294 66.4641C88.3993 71.6538 90.8622 75.5708 87.8234 81.6255C85.7035 85.8267 82.444 88.5945 78.094 90.0032C74.4302 91.1894 70.7542 89.4718 67.8133 90.7198C62.6546 92.9069 58.856 97.9854 54.751 99.2705C53.1703 99.7648 51.5896 100.012 50.0089 99.9996C48.4282 100.012 46.8475 99.7648 45.2668 99.2705C41.1618 97.9854 37.3632 92.9069 32.2045 90.7198C29.2636 89.4718 25.5876 91.2017 21.9238 90.0032C17.5738 88.5945 14.3143 85.8267 12.1945 81.6255C9.14332 75.5708 11.6063 71.6538 9.38839 66.4641C8.34684 64.0175 3.82528 61.8798 1.68091 58.0246C-1.49276 52.3777 -0.218391 46.9532 5.89613 38.8103C9.58445 33.8924 10.2216 30.5438 10.4544 26.911C10.675 23.5376 10.8098 20.1273 13.7139 15.7778C18.7011 8.31446 28.4304 12.4909 33.6749 9.46361C37.4123 7.31358 40.4389 3.91555 43.1224 2.198C45.23 0.826431 47.3376 -0.112662 49.9967 0.0109032Z"
      fill="#F15D2E"
    />
    <path
      d="M35.9198 44.3855L38.7724 62.158L34.593 62.8288L26.429 54.5611L26.3209 54.5785L27.8196 63.916L22.8116 64.7198L19.959 46.9473L24.2104 46.2649L32.2608 54.5152L32.4049 54.4921L30.9118 45.1893L35.9198 44.3855Z"
      fill="white"
    />
    <path
      d="M41.1413 61.7778L38.2887 44.0053L51.5834 41.8714L52.2074 45.7591L43.9207 47.0892L44.411 50.1438L52.0131 48.9237L52.6371 52.8114L45.035 54.0316L45.5253 57.0862L53.7759 55.762L54.3999 59.6497L41.1413 61.7778Z"
      fill="white"
    />
    <path
      d="M61.6035 58.4935L53.3105 41.5942L58.895 40.6978L63.172 51.2273L63.3162 51.2042L64.5515 39.7899L68.9471 39.0844L73.6981 49.5734L73.8422 49.5503L74.6036 38.1765L80.1881 37.2802L77.6003 55.9259L72.8085 56.695L68.0805 47.2705L67.9364 47.2937L66.3953 57.7244L61.6035 58.4935Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>
