<template>
  <img
    :class="{ loading: !loaded }"
    :src="src"
    :alt="alt"
    @load="loaded = true"
  />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>
