// https://stackoverflow.com/questions/10238084/ios-safari-how-to-disable-overscroll-but-allow-scrollable-divs-to-scroll-norma
function preventBodyScroll(el) {
  if (el.scrollTop === 0) {
    el.scrollTop = 1;
  }

  if (el.scrollTop === el.scrollHeight - el.offsetHeight) {
    el.scrollTop = el.scrollHeight - el.offsetHeight - 1;
  }
}

function onScroll(event) {
  preventBodyScroll(event.target);
}

export default {
  mounted(el) {
    preventBodyScroll(el);
    el.addEventListener('scroll', onScroll);
  },
  unmounted(el) {
    el.removeEventListener('scroll', onScroll);
  },
};
