<template>
  <div
    role="button"
    tabindex="0"
    class="relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-theme-primary text-theme-button-text transition-all active:bg-theme-primary/90"
    :class="{ 'shadow-lg': shadow }"
  >
    <slot>
      <Component :is="icon" v-if="icon" class="h-5 w-5" />
      <div v-else class="h-5 w-5 empty:hidden"><slot name="icon" /></div>
    </slot>
    <span
      v-if="badge"
      class="absolute -top-1 -right-1 flex min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-theme-primary text-xs font-semibold text-theme-button-text"
      v-text="badge"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: [Function, Object],
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
