<template>
  <div class="flex">
    <div class="flex flex-col items-center">
      <div
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full"
        :class="{
          'bg-theme-primary text-theme-button-text':
            type === 'success' || type === 'error',
          'bg-theme-primary/10 text-theme-primary': type === 'neutral',
        }"
      >
        <CheckBold v-if="type === 'success'" class="block h-6 w-6" />
        <XBold v-if="type === 'error'" class="block h-6 w-6" />
        <ClockBold
          v-if="type === 'neutral'"
          class="block h-6 w-6 animate-pulse"
        />
      </div>
      <div
        v-if="!last"
        class="my-1 h-full min-h-[30px] w-[3px] rounded-full bg-theme-primary/10"
      ></div>
    </div>
    <div class="ml-3 flex h-10 flex-col justify-center">
      <div class="font-medium">
        <slot name="title" />
      </div>
      <div class="text-sm text-theme-text/80">
        <slot name="description" />
      </div>
    </div>
  </div>
</template>
<script>
import CheckBold from '@/phosphoricons/CheckBold.vue';
import XBold from '@/phosphoricons/XBold.vue';
import ClockBold from '@/phosphoricons/ClockBold.vue';

export default {
  components: { ClockBold, XBold, CheckBold },
  props: {
    type: {
      type: String,
      required: true,
      validator: (type) => ['error', 'success', 'neutral'].includes(type),
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
