<template>
  <div class="-mx-2.5 flex flex-wrap">
    <Lazy
      v-for="(product, productIndex) in products"
      :key="product.id"
      render-on-idle
      style="min-height: 100px"
      class="p-2.5"
      :class="{
        'w-full': displayType === 'list',
        'w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5': displayType === 'grid',
        'w-full md:w-1/2 lg:w-1/3 xl:w-1/4': displayType === 'grid-extended',
      }"
    >
      <ProductCard
        class="h-full"
        :product="product"
        :display-type="displayType"
        :is-first="isFirst && productIndex === 0"
      />
    </Lazy>
  </div>
</template>

<script>
import ProductCard from '@menu/Shared/ProductCard.vue';
import Lazy from '@menu/Shared/Lazy.vue';

export default {
  components: { Lazy, ProductCard },
  props: {
    products: {
      type: Array,
      required: true,
    },
    overrideDisplayType: {
      type: String,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayType() {
      if (this.overrideDisplayType) {
        return this.overrideDisplayType;
      }

      if (this.$store.getters.menu?.menuItemsDisplayType) {
        return this.$store.getters.menu.menuItemsDisplayType;
      }

      if (this.$store.state.restaurant.menuItemsDisplayType) {
        return this.$store.state.restaurant.menuItemsDisplayType;
      }

      return 'grid';
    },
  },
};
</script>
