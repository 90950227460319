<template>
  <div class="flex">
    <Component
      :is="badge.component"
      v-for="(badge, badgeIndex) in badges"
      :key="badgeIndex"
      v-tippy="{ content: badge.name }"
      class="h-6 w-6 focus:outline-none ltr:mr-2 rtl:ml-2"
      @click.stop
    />
  </div>
</template>

<script>
import BestsellerBadge from '@menu/Shared/Badges/BestsellerBadge.vue';
import NewBadge from '@menu/Shared/Badges/NewBadge.vue';
import VideoBadge from '@menu/Shared/Badges/VideoBadge.vue';
export default {
  components: { BestsellerBadge },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badges() {
      const badges = [];

      if (this.product.isBestseller) {
        badges.push({
          name: this.$t('Bestseller'),
          component: BestsellerBadge,
        });
      }

      if (this.product.isNew) {
        badges.push({
          name: this.$t('New'),
          component: NewBadge,
        });
      }

      if (this.product.video) {
        badges.push({
          name: this.$t('Video available'),
          component: VideoBadge,
        });
      }

      return badges;
    },
  },
};
</script>
