<template>
  <MainLayout title="Order payment">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <h1 class="flex-1 truncate px-4 text-center text-lg font-medium">
          Order payment
        </h1>
      </div>
    </div>

    <div class="p-4">
      <StripePaymentElement
        v-if="clientSecret"
        :stripe-account="stripeAccount"
        :client-secret="clientSecret"
        :publishable-key="publishableKey"
        :return-url="returnUrl"
      />
      <div v-else class="py-10 text-center text-xl">Please wait...</div>
    </div>
  </MainLayout>
</template>

<script>
import axios from 'axios';
import MainLayout from '@menu/Shared/MainLayout.vue';
import StripePaymentElement from '@menu/Shared/StripePaymentElement.vue';

export default {
  components: { StripePaymentElement, MainLayout },
  data() {
    return {
      stripeAccount: null,
      clientSecret: null,
      publishableKey: null,
      returnUrl: null,
    };
  },
  created() {
    this.initiatePayment();
  },
  methods: {
    initiatePayment() {
      axios
        .post(`/api/orders/${this.$route.params.orderUuid}/initiate-payment`)
        .then((response) => {
          if (response.data.data.skipPayment) {
            this.$router.push({
              name: 'order',
              params: { orderUuid: this.$route.params.orderUuid },
            });
          } else {
            this.stripeAccount = response.data.data.stripeAccount;
            this.clientSecret = response.data.data.clientSecret;
            this.publishableKey = response.data.data.publishableKey;
            this.returnUrl = response.data.data.returnUrl;
          }
        })
        .catch(() => {
          this.$toast.error('Something went wrong.');
        });
    },
  },
};
</script>
