<template>
  <MainLayout title="Main" class="flex min-h-screen flex-col">
    <div
      class="z-30 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <div class="flex-1"></div>
        <LanguagePickerCircleButton
          v-if="$store.getters.availableLanguages.length > 1"
        />
      </div>
    </div>
    <div class="relative mx-auto mt-2 w-full max-w-xl px-6 pb-10">
      <div
        v-if="restaurant.logo"
        class="mb-6 h-48 w-full bg-contain bg-center bg-no-repeat"
        :style="{ backgroundImage: `url('${restaurant.logo.url}')` }"
      />
      <h1 class="mb-8 text-center text-xl font-bold">
        {{ restaurant.name }}
      </h1>
      <div class="flex flex-col">
        <RouterLink
          v-for="menu in restaurant.menus"
          :key="menu.id"
          :to="{ name: 'home', params: { menuSlug: menu.slug } }"
          class="menu-button-primary mb-3"
        >
          {{ $getTranslation(menu.name) }}
        </RouterLink>
      </div>
      <div class="mt-8 flex flex-col empty:hidden">
        <RouterLink
          v-if="restaurant.forms.length > 0 && restaurant.menus.length > 0"
          :to="{ name: 'form', params: { menuSlug: restaurant.menus[0].slug } }"
          class="menu-button-secondary mb-3"
        >
          <span class="flex items-center justify-center">
            <ChatTextBold class="h-5 w-5 flex-shrink-0 ltr:mr-2 rtl:ml-2" />
            <span>{{ $t('Leave feedback') }}</span>
          </span>
        </RouterLink>
        <a
          v-if="restaurant.googleReviewsUrl"
          :href="restaurant.googleReviewsUrl"
          target="_blank"
          rel="nofollow"
          class="menu-button-secondary mb-3"
        >
          <span class="flex items-center justify-center">
            <GoogleLogoBold class="h-5 w-5 flex-shrink-0 ltr:mr-2 rtl:ml-2" />

            <span>{{ $t('Leave Google review') }}</span>
          </span>
        </a>
        <a
          v-if="restaurant.tripadvisorUrl"
          :href="restaurant.tripadvisorUrl"
          target="_blank"
          rel="nofollow"
          class="menu-button-secondary mb-3"
        >
          <span class="flex items-center justify-center">
            <TripAdvisorLogo class="h-5 w-5 flex-shrink-0 ltr:mr-2 rtl:ml-2" />

            <span>{{ $t('Leave TripAdvisor review') }}</span>
          </span>
        </a>
      </div>
      <div class="mt-4 flex flex-wrap justify-center [&>*]:m-2">
        <a
          v-if="restaurant.instagram"
          :href="restaurant.instagram"
          target="_blank"
          rel="nofollow"
        >
          <InstagramLogoRegular class="h-8 w-8" />
        </a>
        <a
          v-if="restaurant.tiktok"
          :href="restaurant.tiktok"
          target="_blank"
          rel="nofollow"
        >
          <TiktokLogoRegular class="h-8 w-8" />
        </a>
        <a
          v-if="restaurant.facebook"
          :href="restaurant.facebook"
          target="_blank"
          rel="nofollow"
        >
          <FacebookLogoRegular class="h-8 w-8" />
        </a>
        <a
          v-if="restaurant.whatsapp"
          :href="restaurant.whatsapp"
          target="_blank"
          rel="nofollow"
        >
          <WhatsappLogoRegular class="h-8 w-8" />
        </a>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapState } from 'vuex';
import LanguagePickerCircleButton from '@menu/Shared/LanguagePickerCircleButton.vue';
import MainLayout from '@menu/Shared/MainLayout.vue';
import GoogleLogoBold from '@/phosphoricons/GoogleLogoBold.vue';
import WhatsappLogoRegular from '@/phosphoricons/WhatsappLogoRegular.vue';
import FacebookLogoRegular from '@/phosphoricons/FacebookLogoRegular.vue';
import TiktokLogoRegular from '@/phosphoricons/TiktokLogoRegular.vue';
import InstagramLogoRegular from '@/phosphoricons/InstagramLogoRegular.vue';
import TripAdvisorLogo from '@/icons/TripAdvisorLogo.vue';
import ChatTextBold from '@/phosphoricons/ChatTextBold.vue';

export default {
  components: {
    ChatTextBold,
    TripAdvisorLogo,
    InstagramLogoRegular,
    TiktokLogoRegular,
    FacebookLogoRegular,
    WhatsappLogoRegular,
    GoogleLogoBold,
    MainLayout,
    LanguagePickerCircleButton,
  },
  computed: {
    ...mapState(['restaurant']),
  },
};
</script>
