<template>
  <div
    :style="{
      background: menu.homePageBackground
        ? `url('${menu.homePageBackground.url}')`
        : null,
    }"
    style="
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    "
    class="relative flex min-h-[var(--screen-height)] flex-col justify-end bg-theme-background"
  >
    <div style="padding-top: var(--safe-area-inset-top)">
      <div class="p-4">
        <ADeepCircleButton
          v-if="restaurant.isWelcomePageEnabled"
          @click="$router.push({ name: 'menuSelection' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="mb-14 mt-4 flex justify-center">
      <RouterLink :to="{ name: 'menu' }" class="menu-button-primary uppercase">
        {{ $t('Open menu') }}
      </RouterLink>
    </div>
    <div
      v-if="menu.isCategoryTypesNavbarEnabled"
      class="flex w-full justify-center px-1"
    >
      <CategoryTypeNavBar />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import CategoryTypeNavBar from '@menu/Shared/CategoryTypeNavBar.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';
export default {
  components: { ArrowLeftBold, CategoryTypeNavBar, ADeepCircleButton },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu']),
  },
};
</script>
