<template>
  <div
    class="flex w-full max-w-md rounded-t-xl border-t border-l border-r border-theme-text/5 bg-theme-background/90 backdrop-blur"
    style="
      padding-bottom: var(--safe-area-inset-bottom);
      padding-left: var(--safe-area-inset-left);
      padding-right: var(--safe-area-inset-right);
      margin-top: calc(-1 * var(--safe-area-inset-top));
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    "
  >
    <div class="flex w-full justify-center px-6">
      <div
        v-for="(type, typeIndex) in availableCategoryTypes"
        :key="typeIndex"
        type="button"
        class="flex min-w-[4rem] flex-1 cursor-pointer appearance-none flex-col items-center py-2 transition"
        @click="
          $router.push({
            name: 'menu',
            query: {
              ...$route.query,
              category_type:
                $route.query.category_type !== type.slug
                  ? type.slug
                  : undefined,
            },
          })
        "
      >
        <div
          class="-mt-4 block h-10 w-10 rounded-full px-2 transition-[padding,color,background-color]"
          :class="[
            $route.query.category_type === type.slug
              ? 'bg-theme-primary py-2 text-theme-button-text'
              : 'pt-4',
          ]"
        >
          <CategoryTypeIcon :type="type.slug" class="max-h-full max-w-full" />
        </div>
        <div
          class="mt-1 text-center text-xs font-medium"
          :class="[$route.query.category_type === type.slug ? '' : '']"
        >
          {{ type.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryTypeIcon from '@menu/Shared/CategoryTypeIcon.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CategoryTypeIcon,
  },
  computed: {
    ...mapGetters(['availableCategoryTypes']),
  },
};
</script>
