<template>
  <button
    v-if="isSimplifiedButtonPreferred"
    type="button"
    class="mb-2 rounded-lg py-2 px-3 text-left font-medium"
    :class="
      focused
        ? 'bg-theme-primary text-theme-button-text'
        : 'bg-theme-text/5 text-theme-text active:bg-theme-text/10'
    "
  >
    {{ $getTranslation(category.name) }}
  </button>
  <button
    v-else
    type="button"
    class="relative mb-3 flex shrink-0 flex-col items-start justify-end overflow-hidden rounded-md bg-gradient-to-t from-theme-primary/20 to-theme-primary/5 bg-cover bg-center font-medium"
    :style="{
      backgroundImage: hasImage
        ? `url('${category.image.thumbnailUrl}')`
        : null,
    }"
    style="min-height: 6rem"
  >
    <span
      v-if="showName"
      class="z-10 mx-2 mb-2 mt-2 block rounded bg-theme-background py-1 px-2 text-left text-sm text-theme-text"
    >
      {{ $getTranslation(category.name) }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasImage() {
      return Boolean(this.category.image?.thumbnailUrl);
    },
    showName() {
      if (!this.hasImage) {
        return true;
      }

      // todo
      if (['mist'].includes(this.$store.state.restaurant.slug)) {
        return false;
      }

      return true;
    },
    isSimplifiedButtonPreferred() {
      if (['kata'].includes(this.$store.state.restaurant.slug)) {
        return true;
      }

      return false;
    },
  },
};
</script>
