<template>
  <div
    class="relative w-full overflow-hidden rounded-xl"
    :style="{ height: `${containerHeight ?? 0}px` }"
    :class="{ 'skeleton-loading': loading }"
  >
    <video
      v-if="bannerVideo && $store.state.onLine"
      class="relative z-20 mx-auto h-full"
      :class="shouldCrop ? 'w-full object-cover' : 'w-auto'"
      autoplay
      muted
      playsinline
      loop
      :poster="banner.url"
      width="100%"
      @playing="loading = false"
    >
      <source :src="bannerVideo.url" type="video/mp4" />
    </video>
    <img
      v-else
      :src="banner.url"
      alt=""
      class="relative z-20 mx-auto h-full"
      :class="shouldCrop ? 'w-full object-cover' : 'w-auto'"
      @load="loading = false"
    />

    <div
      v-if="!shouldCrop"
      :style="{ backgroundImage: `url('${banner.url}')` }"
      class="absolute inset-0 bg-center blur-2xl"
    />
  </div>
</template>
<script>
export default {
  props: {
    banner: {
      type: Object,
      required: true,
    },
    bannerVideo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      containerWidth: null,
      windowHeight: null,
    };
  },
  computed: {
    containerHeight() {
      if (this.shouldCrop && this.windowHeight && this.containerWidth) {
        return Math.min(
          (this.containerWidth / 1200) * 700,
          this.windowHeight * 0.5,
        );
      }

      if (
        this.banner.height &&
        this.banner.width &&
        this.windowHeight &&
        this.containerWidth
      ) {
        return Math.min(
          (this.containerWidth / this.banner.width) * this.banner.height,
          this.windowHeight * 0.5,
        );
      }

      return null;
    },
    shouldCrop() {
      return (
        ['kata', 'maisondecurry'].includes(this.$store.state.restaurant.slug) ||
        !this.banner.height ||
        !this.banner.width
      );
    },
  },
  mounted() {
    this.updateMeasurements();
    window.addEventListener('resize', this.updateMeasurements);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateMeasurements);
  },
  methods: {
    updateMeasurements() {
      this.containerWidth = this.getContainerWidth();
      this.windowHeight = this.getWindowHeight();

      // To fix the issue where user rotates device.
      setTimeout(() => {
        this.containerWidth = this.getContainerWidth();
        this.windowHeight = this.getWindowHeight();
      });
    },
    getContainerWidth() {
      return this.$el.clientWidth;
    },
    getWindowHeight() {
      const windowHeight = window.outerHeight;

      // On some iOS devices, window.outerHeight returns 0.
      // In that case, we'll use window.innerHeight instead.
      if (windowHeight === 0) {
        return window.innerHeight;
      }

      return windowHeight;
    },
  },
};
</script>
