<template>
  <MainLayout title="Favorites">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <ADeepCircleButton
          class="flex-shrink-0"
          @click="$router.push({ name: 'menu' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
        <div class="flex-1 truncate px-4 text-center text-lg font-medium">
          {{ $t('Favorites') }}
        </div>
        <div class="h-10 w-10 flex-shrink-0"></div>
      </div>
    </div>
    <div class="p-4 pb-8">
      <div>
        <ProductCards
          :products="favoriteProducts"
          override-display-type="list"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@menu/Shared/MainLayout.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import { mapGetters } from 'vuex';
import ProductCards from '@menu/Shared/ProductCards.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';

export default {
  components: {
    ArrowLeftBold,
    ProductCards,
    ADeepCircleButton,
    MainLayout,
  },
  computed: {
    ...mapGetters(['favoriteProducts']),
  },
};
</script>
