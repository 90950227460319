<template>
  <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.0875 111.906C46.3443 111.906 32 126.246 32 143.933C32 161.621 46.3443 175.96 64.0374 175.96C81.7306 175.96 96.075 161.621 96.075 143.933C96.125 126.246 81.7805 111.906 64.0875 111.906ZM64.0875 166.867C51.4424 166.867 41.1464 156.624 41.1464 143.933C41.1464 131.243 51.3925 121 64.0875 121C76.7826 121 86.9786 131.292 86.9786 143.933C86.9786 156.575 76.7325 166.867 64.0875 166.867Z"
      fill="currentColor"
    />
    <path
      d="M64.1938 159.815C72.9993 159.815 80.1377 152.679 80.1377 143.876C80.1377 135.074 72.9993 127.938 64.1938 127.938C55.3884 127.938 48.25 135.074 48.25 143.876C48.25 152.679 55.3884 159.815 64.1938 159.815Z"
      fill="currentColor"
    />
    <path
      d="M192.037 111.906C174.344 111.906 160 126.246 160 143.933C160 161.621 174.344 175.96 192.037 175.96C209.731 175.96 224.075 161.621 224.075 143.933C224.075 126.246 209.731 111.906 192.037 111.906ZM192.037 166.867C179.392 166.867 169.096 156.624 169.096 143.933C169.096 131.243 179.342 121 192.037 121C204.733 121 214.928 131.292 214.928 143.933C214.928 156.575 204.683 166.867 192.037 166.867Z"
      fill="currentColor"
    />
    <path
      d="M192.132 159.815C200.936 159.815 208.076 152.679 208.076 143.876C208.076 135.074 200.936 127.938 192.132 127.938C183.327 127.938 176.188 135.074 176.188 143.876C176.188 152.679 183.327 159.815 192.132 159.815Z"
      fill="currentColor"
    />
    <path
      d="M237.107 98.564L255.95 79.8773H217.765C196.373 62.4898 160.837 48 127.9 48C94.913 48 59.6766 62.4898 38.2851 79.8773H0L18.8427 98.564C7.19718 110.156 0 126.194 0 143.932C0 179.307 28.6888 207.986 64.075 207.986C80.3186 207.986 95.1629 201.941 106.458 191.947L128.15 207.886L149.592 192.298L149.342 191.698C160.688 201.79 175.581 207.936 191.974 207.936C227.362 207.936 256.05 179.256 256.05 143.882C256 126.194 248.754 110.156 237.107 98.564ZM183.928 80.377C153.59 84.1742 129.799 109.156 128 140.084C126.151 109.107 102.31 84.0744 71.872 80.377C87.1659 69.3848 106.658 63.9886 127.9 63.9886C149.142 63.9886 168.634 69.3848 183.928 80.377ZM64.075 191.998C37.4853 191.998 15.9938 170.462 15.9938 143.932C15.9938 117.401 37.5354 95.8659 64.075 95.8659C90.6146 95.8659 112.156 117.401 112.156 143.932C112.106 170.462 90.6146 191.998 64.075 191.998ZM192.026 191.998C165.485 191.998 143.944 170.462 143.944 143.932C143.944 117.401 165.485 95.8659 192.026 95.8659C218.565 95.8659 240.106 117.401 240.106 143.932C240.056 170.462 218.565 191.998 192.026 191.998Z"
      fill="currentColor"
    />
  </svg>
</template>
