<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.9967 0.0109032C52.6557 -0.100305 54.751 0.826431 56.8709 2.18564C59.5544 3.90319 62.581 7.30123 66.3184 9.45125C71.5752 12.4786 81.3045 8.3021 86.2794 15.7654C89.1835 20.1149 89.3183 23.5253 89.5388 26.8986C89.7717 30.5314 90.4087 33.88 94.0971 38.7979C100.212 46.9532 101.486 52.3777 98.3368 58.0246C96.1924 61.8798 91.671 64.0175 90.6294 66.4641C88.3993 71.6538 90.8622 75.5708 87.8234 81.6255C85.7035 85.8267 82.444 88.5945 78.094 90.0032C74.4302 91.1894 70.7542 89.4718 67.8133 90.7198C62.6546 92.9069 58.856 97.9854 54.751 99.2705C53.1703 99.7648 51.5896 100.012 50.0089 99.9996C48.4282 100.012 46.8475 99.7648 45.2668 99.2705C41.1618 97.9854 37.3632 92.9069 32.2045 90.7198C29.2636 89.4718 25.5876 91.2017 21.9238 90.0032C17.5738 88.5945 14.3143 85.8267 12.1945 81.6255C9.14332 75.5708 11.6063 71.6538 9.38839 66.4641C8.34684 64.0175 3.82528 61.8798 1.68091 58.0246C-1.49276 52.3777 -0.218389 46.9532 5.89613 38.8103C9.58445 33.8924 10.2216 30.5438 10.4544 26.911C10.675 23.5376 10.8098 20.1273 13.7139 15.7778C18.7011 8.31446 28.4304 12.4909 33.6749 9.46361C37.4123 7.31358 40.4389 3.91555 43.1224 2.198C45.23 0.826431 47.3376 -0.112662 49.9967 0.0109032Z"
      fill="#3C4D5C"
    />
    <path
      d="M61.7682 37.1626L65.4932 58.6194C65.6343 59.4324 65.4513 60.2673 64.9845 60.9405C64.5176 61.6137 63.8052 62.0701 63.0038 62.2092L35.8107 66.93C35.0094 67.0691 34.1848 66.8796 33.5184 66.4031C32.8519 65.9267 32.3982 65.2023 32.2571 64.3893L28.5321 42.9325C28.391 42.1195 28.574 41.2846 29.0408 40.6113C29.5077 39.9381 30.2201 39.4818 31.0215 39.3427L58.2146 34.6218C59.0159 34.4827 59.8405 34.6723 60.5069 35.1487C61.1734 35.6252 61.6271 36.3496 61.7682 37.1626ZM72.7293 35.309C72.507 35.2931 72.2841 35.3251 72.0753 35.4028C71.8665 35.4806 71.6765 35.6023 71.5179 35.7601L65.9193 41.1508C65.828 41.2388 65.7597 41.3484 65.7204 41.4698C65.681 41.5913 65.672 41.7209 65.694 41.8471L67.6103 52.8859C67.6321 53.0121 67.6844 53.131 67.7623 53.2321C67.8403 53.3332 67.9416 53.4134 68.0572 53.4655L75.1852 56.6724C75.4521 56.7925 75.7468 56.8333 76.0348 56.7899C76.3229 56.7466 76.5924 56.6208 76.8118 56.4275C77.0136 56.2415 77.1647 56.0059 77.2505 55.7433C77.3364 55.4806 77.3542 55.1997 77.3023 54.9273L74.1201 36.5971C74.0613 36.2571 73.8927 35.9461 73.6409 35.7129C73.3892 35.4798 73.0685 35.3377 72.7293 35.309Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>
