<template>
  <MainLayout title="Form">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <ADeepCircleButton
          class="flex-shrink-0"
          @click="$router.push({ name: 'menu' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
        <div class="flex-1 truncate px-4 text-center text-lg font-medium">
          {{ $getTranslation(form.title) }}
        </div>
        <div class="h-10 w-10 flex-shrink-0"></div>
      </div>
    </div>
    <div class="p-4">
      <div v-if="!submitted" class="">
        <div
          v-if="answers.some((answer) => answer.question.isMandatory)"
          class="mb-8 text-xs"
        >
          <span class="text-red-500">* </span>
          <span>{{ $t('These fields are required') }}</span>
        </div>
        <form @submit.prevent="submit">
          <div
            v-for="(answer, answerIndex) in answers"
            :key="answerIndex"
            class="mb-6"
          >
            <div>
              {{ $getTranslation(answer.question.question) }}
              <span v-if="answer.question.isMandatory" class="text-red-500"
                >*</span
              >
            </div>

            <div class="mt-2">
              <template v-if="answer.question.type === 'text'">
                <textarea
                  v-model="answer.answer"
                  rows="3"
                  class="menu-form-input w-full"
                  :required="answer.question.isMandatory"
                />
              </template>

              <template v-if="answer.question.type === 'rating'">
                <ARating v-model="answer.answer" />
              </template>
            </div>
          </div>

          <button
            type="submit"
            class="menu-button-primary w-max"
            :disabled="!isValid || submitting"
          >
            {{ submitting ? `${$t('Wait')}...` : $t('Submit') }}
          </button>
        </form>
      </div>
      <div v-else class="text-center">
        {{ $t('Thanks') }}
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@menu/Shared/MainLayout.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import { mapGetters, mapState } from 'vuex';
import ARating from '@menu/Shared/ARating.vue';
import axios from 'axios';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';

export default {
  components: {
    ArrowLeftBold,
    ARating,
    ADeepCircleButton,
    MainLayout,
  },
  data() {
    return {
      answers: [],
      submitting: false,
      submitted: false,
    };
  },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu']),
    form() {
      return this.restaurant.forms[0];
    },
    isValid() {
      return !this.answers.some((answer) => {
        if (!answer.question.isMandatory) {
          return false;
        }

        return this.isAnswerEmpty(answer);
      });
    },
  },
  created() {
    this.answers = this.form.questions.map((question) => ({
      question,
      answer: (() => {
        if (question.type === 'rating') {
          return 0;
        }
        if (question.type === 'text') {
          return '';
        }
        return null;
      })(),
    }));
  },
  methods: {
    isAnswerEmpty(answer) {
      if (answer.question.type === 'rating' && answer.answer === 0) {
        return true;
      }

      if (answer.question.type === 'text' && answer.answer === '') {
        return true;
      }

      return false;
    },
    submit() {
      if (!this.isValid) {
        return;
      }

      this.submitting = true;

      axios
        .post('/api/form-submissions', {
          restaurantId: this.restaurant.id,
          menuId: this.menu.id,
          formId: this.form.id,
          answers: this.answers.map((answer) => ({
            questionId: answer.question.id,
            answer: this.isAnswerEmpty(answer) ? null : answer.answer,
          })),
        })
        .finally(() => {
          this.submitted = true;
          this.submitting = false;
        });
    },
  },
};
</script>
