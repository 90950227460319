<template>
  <MainLayout title="Cart">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <ADeepCircleButton
          class="flex-shrink-0"
          @click="$router.push({ name: 'menu' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
        <div class="flex-1 truncate px-4 text-center text-lg font-medium">
          {{ $t('Cart') }}
        </div>
        <div class="h-10 w-10 flex-shrink-0"></div>
      </div>
    </div>
    <form
      v-if="cartItems.length > 0"
      class="mx-auto max-w-xl p-4 pb-8"
      @submit.prevent="placeOrder"
    >
      <div>
        <div>
          <div
            v-for="(cartItem, cartItemIndex) in cartItems"
            :key="cartItemIndex"
            class="mb-5 flex justify-between"
          >
            <div>
              <div>
                {{ $getTranslation(cartItem.product.name) }}
                <template v-if="$getTranslation(cartItem.variant.name)"
                  >- {{ $getTranslation(cartItem.variant.name) }}</template
                >
              </div>
              <div v-if="cartItem.modifiers.length > 0" class="opacity-70">
                {{
                  cartItem.modifiers
                    .map(({ name }) => $getTranslation(name))
                    .join(', ')
                }}
              </div>
              <div v-if="cartItem.price > 0" class="font-semibold">
                {{ $formatPrice(cartItem.price * cartItem.quantity) }}
                <span v-if="cartItem.quantity > 1" class="text-sm">
                  ({{ $formatPrice(cartItem.price) }} x {{ cartItem.quantity }})
                </span>
              </div>
              <div class="mt-2 flex items-center">
                <QuantityPicker
                  :model-value="cartItem.quantity"
                  :min="0"
                  @update:model-value="
                    (quantity) => setQuantityForCartItem(cartItem, quantity)
                  "
                />

                <button
                  type="button"
                  class="rounded p-1 text-theme-primary/50 transition hover:text-red-500 ltr:ml-3 rtl:mr-3"
                  @click="setQuantityForCartItem(cartItem, 0)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-5 w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="ml-3">
              <div class="w-20">
                <div class="relative pb-[100%]">
                  <AImage
                    class="absolute inset-0 h-full w-full overflow-hidden rounded-md object-cover object-center"
                    :src="
                      cartItem.product.image?.thumbnailUrl ||
                      $store.state.restaurant.fallbackProductImage?.url
                    "
                    :alt="$getTranslation(cartItem.product.name)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="$store.getters.cartItemsTotalPrice > 0"
            class="font-semibold"
          >
            Total:
            {{ $formatPrice($store.getters.cartItemsTotalPrice) }}
          </div>
        </div>
        <div class="mt-10 space-y-6">
          <div v-if="showOrderTypeSelector">
            <label class="mb-1 inline-block"
              >Order type <span class="text-red-500">*</span></label
            >

            <div class="flex flex-col">
              <OptionGroupItem
                v-for="orderType in orderTypes"
                :key="orderType.id"
                :is-selected="selectedOrderTypeId === orderType.id"
                @click="selectedOrderTypeId = orderType.id"
              >
                {{ $getTranslation(orderType.name) }}

                <template #description>
                  {{ $getTranslation(orderType.description) }}
                </template>
              </OptionGroupItem>
            </div>
          </div>

          <template v-if="selectedOrderType">
            <div v-if="selectedOrderType.isNameEnabled" class="flex flex-col">
              <label for="customerName" class="mb-1 inline-block"
                >Name
                <span
                  v-if="selectedOrderType.isNameRequired"
                  class="text-red-500"
                  >*</span
                ></label
              >
              <input
                id="customerName"
                v-model="customerName"
                :required="selectedOrderType.isNameRequired"
                type="text"
                class="menu-form-input"
              />
            </div>
            <div v-if="selectedOrderType.isPhoneEnabled" class="flex flex-col">
              <label for="customerPhone" class="mb-1 inline-block"
                >Phone
                <span
                  v-if="selectedOrderType.isPhoneRequired"
                  class="text-red-500"
                  >*</span
                ></label
              >
              <input
                id="customerPhone"
                v-model="customerPhone"
                :required="selectedOrderType.isPhoneRequired"
                type="text"
                class="menu-form-input"
              />
              <div class="mt-1 text-sm text-theme-text">
                We will contact you if we have questions regarding your order.
              </div>
            </div>
            <div v-if="selectedOrderType.isEmailEnabled" class="flex flex-col">
              <label for="customerEmail" class="mb-1 inline-block"
                >Email
                <span
                  v-if="selectedOrderType.isEmailRequired"
                  class="text-red-500"
                  >*</span
                ></label
              >
              <input
                id="customerEmail"
                v-model="customerEmail"
                :required="selectedOrderType.isEmailRequired"
                type="email"
                class="menu-form-input"
              />
              <div class="mt-1 text-sm text-theme-text">
                We will send you order status updates to this email.
              </div>
            </div>
            <div
              v-if="selectedOrderType.type === 'DELIVERY'"
              class="flex flex-col"
            >
              <label for="customerAddress" class="mb-1 inline-block"
                >Address <span class="text-red-500">*</span></label
              >
              <textarea
                id="customerAddress"
                v-model="customerAddress"
                class="menu-form-input"
                required
                placeholder="City, area, street, building, apartment."
              />
            </div>
            <div
              v-if="selectedOrderType.type === 'TABLE'"
              class="flex flex-col"
            >
              <label for="customerTable" class="mb-1 inline-block">
                {{ $getTranslation(selectedOrderType.name) }}
                <span class="text-red-500">*</span></label
              >
              <input
                id="customerTable"
                v-model="customerTable"
                required
                type="text"
                class="menu-form-input"
                :disabled="isCustomerTableReadonly"
              />
            </div>
            <div
              v-if="
                selectedOrderType.name.en
                  ?.toLowerCase()
                  .split(' ')
                  .includes('car')
              "
              class="flex flex-col"
            >
              <label for="customerCarPlateNumber" class="mb-1 inline-block">
                Car plate number
                <span class="text-red-500">*</span></label
              >
              <input
                id="customerCarPlateNumber"
                v-model="customerCarPlateNumber"
                required
                type="text"
                class="menu-form-input"
                placeholder="Enter your car plate number"
              />
            </div>
            <div v-if="selectedOrderType.isNoteEnabled" class="flex flex-col">
              <label for="customerNote" class="mb-1 inline-block"
                >Special instructions</label
              >
              <textarea
                id="customerNote"
                v-model="customerNote"
                class="menu-form-input"
                placeholder="Add a note or any instructions."
              />
            </div>
          </template>
          <div v-if="orderPaymentTypes.length > 0">
            <label class="mb-1 inline-block"
              >Payment type <span class="text-red-500">*</span></label
            >

            <div class="flex flex-col">
              <OptionGroupItem
                v-for="orderPaymentType in orderPaymentTypes"
                :key="orderPaymentType.id"
                :is-selected="
                  selectedOrderPaymentTypeId === orderPaymentType.id
                "
                @click="selectedOrderPaymentTypeId = orderPaymentType.id"
              >
                {{ $getTranslation(orderPaymentType.name) }}

                <template #description>
                  {{ $getTranslation(orderPaymentType.description) }}
                </template>
              </OptionGroupItem>
            </div>
          </div>
        </div>

        <div
          class="mx-auto mt-8 max-w-xs text-center text-sm text-theme-text/80"
        >
          By placing an order, I agree to Apetito Menu's
          <a
            href="https://apetitomenu.com/terms-of-service-for-consumers"
            target="_blank"
            class="text-theme-text hover:underline"
            >Terms of Service</a
          >
          and
          <a
            href="https://apetitomenu.com/privacy-policy-for-consumers"
            target="_blank"
            class="text-theme-text hover:underline"
            >Privacy Policy</a
          >.
        </div>

        <div class="mt-8">
          <div>
            <button
              type="submit"
              class="menu-button-primary w-full"
              :disabled="placingOrder"
            >
              <template v-if="placingOrder"> Placing order... </template>
              <template v-else-if="selectedOrderPaymentType?.isOnlinePayment">
                Place order & Pay online
              </template>
              <template v-else> Place order </template>
            </button>
          </div>
        </div>
        <div class="h-24"></div>
      </div>
    </form>
    <div v-else class="p-4">Your cart is empty...</div>
  </MainLayout>
</template>

<script>
import MainLayout from '@menu/Shared/MainLayout.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import { mapGetters } from 'vuex';
import QuantityPicker from '@menu/Shared/QuantityPicker.vue';
import AImage from '@menu/Shared/AImage.vue';
import axios from 'axios';
import OptionGroupItem from '@menu/Shared/OptionGroupItem.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';

export default {
  components: {
    ArrowLeftBold,
    OptionGroupItem,
    AImage,
    QuantityPicker,
    ADeepCircleButton,
    MainLayout,
  },
  data() {
    return {
      selectedOrderTypeId: null,
      selectedOrderPaymentTypeId: null,
      customerName: '',
      customerPhone: '',
      customerEmail: '',
      customerAddress: '',
      customerTable: (() => {
        try {
          return sessionStorage.getItem('table') || '';
        } catch (e) {
          return '';
        }
      })(),
      customerCarPlateNumber: '',
      // initially added for blue beach
      isCustomerTableReadonly: (() => {
        try {
          return Boolean(sessionStorage.getItem('table'));
        } catch (e) {
          return false;
        }
      })(),
      customerNote: '',
      placingOrder: false,
    };
  },
  computed: {
    ...mapGetters([
      'cartItems',
      'cartItemsTotalPrice',
      'cartItemsTotalQuantity',
    ]),
    orderingConfiguration() {
      return this.$store.state.restaurant.orderingConfiguration;
    },
    orderTypes() {
      return this.orderingConfiguration.orderTypes;
    },
    orderPaymentTypes() {
      return this.orderingConfiguration.orderPaymentTypes;
    },
    selectedOrderType() {
      return this.orderTypes.find(({ id }) => id === this.selectedOrderTypeId);
    },
    selectedOrderPaymentType() {
      return this.orderPaymentTypes.find(
        ({ id }) => id === this.selectedOrderPaymentTypeId,
      );
    },
    showOrderTypeSelector() {
      // initially added for blue beach
      if (this.orderTypes.length === 1 && this.orderTypes[0].type === 'TABLE') {
        return false;
      }

      return this.orderTypes.length > 1;
    },
  },
  created() {
    this.selectedOrderTypeId =
      this.orderTypes.length > 0 ? this.orderTypes[0].id : null;

    this.selectedOrderPaymentTypeId =
      this.orderPaymentTypes.length > 0 ? this.orderPaymentTypes[0].id : null;
  },
  methods: {
    placeOrder() {
      this.placingOrder = true;

      let customerNote = this.customerNote;

      if (this.customerCarPlateNumber.length > 0) {
        customerNote = `Car plate number: ${this.customerCarPlateNumber}\n\n${customerNote}`;
      }

      axios
        .post('/api/orders', {
          restaurantId: this.$store.state.restaurant.id,
          menuId: this.$store.getters.menu.id,
          orderTypeId: this.selectedOrderTypeId,
          orderPaymentTypeId: this.selectedOrderPaymentTypeId,
          visitorUuid: this.$store.state.visitorUuid,
          items: this.cartItems.map((cartItem) => ({
            productId: cartItem.product.id,
            variantId: cartItem.variant.id,
            modifierIds: cartItem.modifiers.map(({ id }) => id),
            quantity: cartItem.quantity,
          })),
          customerName: this.customerName,
          customerPhone: this.customerPhone,
          customerEmail: this.customerEmail,
          customerAddress: this.customerAddress,
          customerTable: this.customerTable,
          customerNote: customerNote,
        })
        .then((res) => {
          if (res.data.data.redirectToPayment) {
            this.$router.push({
              name: 'orderPay',
              params: { orderUuid: res.data.data.uuid },
            });
          } else {
            this.$router.push({
              name: 'order',
              params: { orderUuid: res.data.data.uuid },
            });
          }

          this.placingOrder = false;
          this.$store.commit('clearCartItems');
        })
        .catch((err) => {
          const message =
            err.response.data.message || 'Something went wrong...';

          this.placingOrder = false;

          this.$toast.error(message, {
            timeout: 4000,
          });

          throw new Error('Order failed: ' + message);
        });
    },
    setQuantityForCartItem(cartItem, quantity) {
      this.$store.commit('setCartItemQuantity', {
        productId: cartItem.product.id,
        variantId: cartItem.variant.id,
        modifierIds: cartItem.modifiers.map(({ id }) => id),
        quantity,
      });
    },
  },
};
</script>
